import {hasOwnProperty, hasString} from '~/utils/obj';
import type {EntryRef, EtherSeo, Image, SeoFallback, SeoInputRef} from '~/interfaces/seo';
import {getFbMetaFields, getTwMetaFields} from '~/utils/seo/social';
import {getRobots} from '~/utils/seo/robots';
import {getCanonical} from '~/utils/seo/canonical';
import {getSeoDescription} from '~/utils/seo/description';
import {getSeoImage} from '~/utils/seo/image';
import {useMultisite} from '~/composables/useMultisite';

// Craft Ether Seo
export const useSeo = (
    seoInput: SeoInputRef,
    entry: EntryRef,
    fallbacks?: SeoFallback
) => computed(() => {
    const {activeSite} = useMultisite();
    const seoRef = initializeEtherSeoData(seoInput, entry, fallbacks);
    const seo = toValue(seoRef);

    if (!seo) {
        return {};
    }

    const metaData = {
        meta: [
            ...getFbMetaFields(seo),
            ...getTwMetaFields(seo),
            getRobots(seo)
        ],
    } as any;

    if (hasString(seo, 'title')) {
        const seoPrepend = toValue(activeSite)?.seo?.prepend;
        metaData.title = seoPrepend && !seo.title.includes(seoPrepend) ?
            `${seo.title} ${seoPrepend}` :
            seo.title;
    }

    if (hasString(seo, 'description')) {
        metaData.meta.push(
            { hid: 'description', name: 'description', content: seo.description },
        );
    }

    if (getCanonical(seo)) {
        metaData.link = [
            getCanonical(seo)
        ];
    }

    return metaData;
});


const initializeEtherSeoData = (
    refSeo: SeoInputRef,
    refEntry: EntryRef,
    fallbacks: SeoFallback = {}
) => computed(() => {
    const seo: EtherSeo = toValue(refSeo) || {};
    const entry = toValue(refEntry);

    if (!entry && !fallbacks) {
        return seo;
    }

    // Set a default description based on introText or searchText if defined
    if (!hasString(seo, 'description')) {
        seo.description = getSeoDescription(entry);
    } else if(hasString(fallbacks, 'seoDescription')) {
        seo.description = fallbacks.seoDescription;
    }

    // Set a default title based on entry title if not defined
    if (!hasString(seo, 'title')) {
        (seo as EtherSeo).title = entry?.title ?? fallbacks.seoTitle ?? '';
    }

    // Set a default image based on entry image, or fallback to static image instead
    if (!hasOwnProperty(seo, 'featuredimage')) {
        const availableImages = [
            entry?.overviewImage,
            entry?.headerImage
        ].filter(Boolean) as Image[][];

        const image = getSeoImage(availableImages);

        if (image) {
            // Set featured image from header- or overview image
            seo.featuredimage = { urlLarge: image };
        } else {
            // Set a generic image
            // seo.featuredimage = { large: this.$config.appURL + '/social.png' };
        }
    }
    return seo;
});


