<template>
    <NuxtLayout
        name="default"
    > 
        <main class="layout__main">
            <BaseError :error="error" />
        </main>
    </NuxtLayout>
</template>

<script setup>
import SiteAssetsQuery from '~/graphql/queries/global/SiteAssets.graphql';

defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const { activeSite } = useMultisite();

// Defaults based on sitename (from config)
provide('siteName', activeSite.value.name);
useHead({
    title: activeSite.value.name,
    meta: [
        {
            name: 'description',
            content: `${activeSite.value.name} - To raise the level of urological care. The EAU represents the leading authority within Europe on urological practice, research and education.`
        }
    ]
});

const { variables: defaultVariables } = useCraftGraphql();

delete defaultVariables.uri;

const variables = ref({
    ...defaultVariables,
    site: toValue(activeSite).handle
});

const { data } = await useAsyncQuery({
    query: SiteAssetsQuery,
    variables
});

// Provice asset urls (from cms)
if (data.value?.site?.siteLogoNavigation?.[0]) {
    provide('navLogoUrl', data.value.site.siteLogoNavigation[0].url);
}
if (data.value?.site?.siteLogoFooter?.[0]) {
    provide('footerLogoUrl', data.value.site.siteLogoFooter[0].url);
}

if (data.value?.site?.siteFavicon) {
    useFavicon(data.value?.site?.siteFavicon?.[0]);
} else {
    useFavicon({
        url180: '/apple-touch-icon-180x180.png',
        url512: '/pwa-512x512.png',
        url192: '/pwa-192x192.png',
        url64: '/favicon-64x64.png'
    });
}
</script>

<style lang="less"></style>
