export const useFavicon = (icons: { url64: string, url192: string, url512: string, url180: string }) => {
    useHead({
        link: [
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '64x64',
                href: `${icons?.url64 || null}`
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '192x192',
                href: `${icons?.url192 || null}`
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '512x512',
                href: `${icons?.url512 || null}`
            },
            {
                rel: 'apple-touch-icon',
                type: 'image/png',
                sizes: '180x180',
                href: `${icons?.url180 || null}`
            }
        ],
        meta: [
            {
                name: 'msapplication-TileColor',
                content: '#FE6B2E'
            },
            {
                name: 'theme-color',
                content: '#FE6B2E'
            }
        ]
    });
};
