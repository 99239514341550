import type { SiteConfig, SiteMap } from '~/interfaces/multisite';

const Sites = `query Sites {
    sites {
        id
        handle
        enabled
        language
        primary
    }
}
`;

interface Site {
    id: string;
    handle: string;
    enabled: boolean;
    primary?: boolean;
    language?: string;
}

export const useMultisite = () => {
    const runtimeConfig = useRuntimeConfig() as {
        public: {
            sites: SiteMap;
            defaults: SiteConfig;
        };
    };

    const url = useRequestURL();

    const activeSite = computed<SiteConfig>(() => {
        let result;
        if (runtimeConfig.public.sites[url?.hostname]) {
            result = runtimeConfig.public.sites[url?.hostname];
        } else {
            result = runtimeConfig.public.defaults;
        }

        return result;
    });

    const featureFlag = (featureId: string): boolean => {
        // If the feature is not disabled, return true.
        return runtimeConfig.public.sites[url?.hostname].disable?.includes(featureId) ?? true;
    };

    const getSites = async(): Promise<Site[]> => {
        const { data } = await useAsyncQuery({
            query: Sites,
        });

        return data?.value?.sites ?? [];
    };

    const getSiteId = async(handle: string): Promise<string | undefined> => {
        const sites = await getSites();
        const site = sites.find((siteConfig: SiteConfig) => siteConfig.handle === handle);
        return site?.id;
    };

    return {
        activeSite,
        featureFlag,
        getSiteId
    };
};
